import axios from 'axios'
import Vue from 'vue'


const http = axios.create({
    baseURL: 'https://cangku.clubj888.com/index.php/api/',//'https://cangku.clubj888.com/index.php/api/',
    headers: { "content-type": "application/x-www-form-urlencoded;charset=UTF-8" }
})

http.interceptors.response.use(res => {
    if (res.data.error_code == 10016) {
        Vue.prototype.$Notify({
            type: 'warning',
            message: res.data.error_msg
        })
        return res
    }
    if (res.data.error_code != 0 && res.data.error_code != 10016) {
        Vue.prototype.$Notify({
            type: 'warning',
            message: res.data.error_msg || '请求失败'
        })
        Vue.prototype.$Toast.clear();
        return
    }

    return res
}, err => {

    Vue.prototype.$Notify({
        type: 'warning',
        message: err.data.error_msg || '接口请求失败'
    })

    return Promise.reject(err)
})

export default http